import p5 from 'p5';

const curtains = (p) => {
  let yoff = 0.0; // 2nd dimension of perlin noise
  let t = 0;
  let fps;

  p.setup = () => {
    const canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    canvas.parent('background');
    p.background(0);
  };

  p.draw = () => {
    p.clear();
    // drawLines(p.color(122, 111, 111), 0.04);
    drawLines(p.color(0, 0, 0), 0.01);

    fps = p.frameRate();
    p.fill(255);
    p.stroke(0);
    p.text('FPS: ' + fps.toFixed(2), 10, p.windowHeight - 10);
  };

  const drawLines = (
    color = p.color(200, 200, 200),
    lerpSpeed,
    strokeWidth = 1
  ) => {
    for (var i = -100; i <= p.windowHeight; i += 2) {
      p.stroke(color);
      p.strokeWeight(strokeWidth);
      p.noFill();
      p.beginShape();
      var yPos = i;
      let xoff = 0;

      const getXVertices = (x) => {
        // let y = lerp(yPos, map(noise(xoff, yoff), 0, 1, 0, p.windowHeight  * (i/130), lerpSpeed);
        // let y = p.map(p.noise(xoff, yoff), 0, 1, 0, p.windowHeight * (i / 130));
        let y = p.map(p.noise(xoff, yoff), 0, 1, 0, p.windowHeight * (i / 130));
        let xmod = x + 20 * p.sin(t * p.PI);
        p.vertex(xmod, yPos + y);
        xoff += -0.09;
      };
      for (let x = -20; x <= p.width + 100; x += 50) {
        getXVertices(x);
      }
      p.endShape();
      yoff += 0.000003;
    }
    t = t + 0.002;
  };
  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };
};

const fire = (p) => {
  const colors = [
    '#000000',
    '#070707',
    '#1f0707',
    '#2f0f07',
    '#470f07',
    '#571707',
    '#671f07',
    '#771f07',
    '#8f2707',
    '#9f2f07',
    '#af3f07',
    '#bf4707',
    '#c74707',
    '#DF4F07',
    '#DF5707',
    '#DF5707',
    '#D75F07',
    '#D7670F',
    '#cf6f0f',
    '#cf770f',
    '#cf7f0f',
    '#CF8717',
    '#C78717',
    '#C78F17',
    '#C7971F',
    '#BF9F1F',
    '#BF9F1F',
    '#BFA727',
    '#BFA727',
    '#BFAF2F',
    '#B7AF2F',
    '#B7B72F',
    '#B7B737',
    '#CFCF6F',
    '#DFDF9F',
    '#EFEFC7',
    '#FFFFFF'
  ];
  const WIDTH = 64;
  const HEIGHT = 64;
  const totalPixels = WIDTH * HEIGHT;
  const pixelSize = 4;
  const firePixels = [];
  p.setup = () => {
    p.createCanvas(WIDTH * pixelSize, HEIGHT * pixelSize);

    for (let i = 0; i <= totalPixels; i++) firePixels[i] = 3;
    for (let column = 0; column <= WIDTH; column++) {
      const overflowPixelIndex = WIDTH * HEIGHT;
      const pixelIndex = overflowPixelIndex - WIDTH + column;

      firePixels[pixelIndex] = 36;
    }
  };
  function propagateFire() {
    for (let x = 0; x < WIDTH; x++) {
      for (let y = 0; y < HEIGHT; y++) {
        const pos = x + WIDTH * y;
        updateFireIntensity(pos);
      }
    }
    renderFire();
  }
  function updateFireIntensity(pixelIndex) {
    const belowIndex = pixelIndex + WIDTH;
    if (belowIndex >= totalPixels) return;

    const decay = p.floor(p.random() * 2);
    const belowPixelFireIntensity = firePixels[belowIndex];
    const newFireIntensity =
      belowPixelFireIntensity - decay >= 0
        ? belowPixelFireIntensity - decay
        : 0;

    firePixels[pixelIndex - decay] = newFireIntensity;
  }
  function renderFire() {
    for (let y = 0; y < HEIGHT; y++) {
      for (let x = 0; x < WIDTH; x++) {
        const pixelIndex = x + WIDTH * y;
        const intensity = firePixels[pixelIndex];
        const color = colors[intensity];
        p.fill(color);
        // strokeWeight(pixelSize);
        p.noStroke();
        // point(x * pixelSize,y * pixelSize);
        p.rect(x * pixelSize, y * pixelSize, pixelSize, pixelSize);
      }
    }
  }
  p.draw = () => {
    p.background(0);
    propagateFire();
    /*   renderFire(); */
  };
};
const homepage = document.querySelector('body.homepage');
if (homepage) {
  const p5Instance = new p5(curtains);
}
